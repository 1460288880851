<template>
  <div class="layout-config h-100 p-10">
    <Row type="flex" :gutter="10">
      <Col span="4">
        <Input v-model="param.query" placeholder="请输入名称" clearable />
      </Col>
      <Col span="2">
        <Button type="primary" @click="getData()">查询</Button>
      </Col>
    </Row>
    <Table
      border
      :columns="cols"
      :data="table.data"
      ref="table"
      class="m-t-10 w-100"
    />
    <Page
      :page="param.current"
      @on-change="getData"
      :page-size="10"
      :total="table.total"
      show-total
      class="text-r m-t-10"
    />
    <Modal v-model="modal.show" title="更新分享" width="600" @on-ok="saveData">
      <Form
        ref="form"
        :model="modal.data"
        :rules="modal.rules"
        :label-width="100"
        class="w-90"
        label-colon
      >
        <FormItem label="分享名称">
          <Input
            v-model="modal.data.title"
            placeholder="请输入分享名称"
            clearable
          />
        </FormItem>
        <FormItem label="分享描述">
          <Input
            v-model="modal.data.context"
            type="textarea"
            placeholder="请输入分享描述"
            clearable
          />
        </FormItem>
        <FormItem label="到期时间">
          <MyDataPicker
            v-model="modal.data.expireTime"
            type="datetime"
            format="yyyy-MM-dd 23:59:59"
          />
        </FormItem>
      </Form>
      <Divider orientation="left">分享活动详情</Divider>
      <Vgroup :label-width="100" v-if="modal.data.activity">
        <Vcell label="活动名称：" :value="modal.data.activity.activityName" />
        <Vcell
          label="活动发起人："
          :value="modal.data.activity.owner.userName"
        />
        <Vcell
          label="所属机构："
          :value="modal.data.activity.organization.orgName"
        />
        <Vcell label="活动详情：" :value="modal.data.activity.activityDetail" />
      </Vgroup>
    </Modal>
    <Modal v-model="qrModal.show" width="400" ok-text="下载" @on-ok="downLoadQrCode">
      <div class="m-t-20 text-c">
        <vue-qr ref="qrCode" :text="qrModal.src" :logoSrc="qrModal.logoSrc"></vue-qr>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getShareActivityList,
  updateShareActivity,
  deleteShareActivity,
} from "@/libs/api/activity";
import { util } from "@/libs";
import ClipboardJS from "clipboard";
import VueQr from 'vue-qr'
export default {
  components: { VueQr },
  data() {
    const _this = this;
    return {
      param: {
        current: 1,
        size: 10,
        order: "createTime",
        rule: "DESC", // ["ASC", "DESC"],
      },
      modal: {
        show: false,
        data: {},
        rules: {},
      },
      qrModal: {
        show: false,
        src: "",
        logoSrc: require('@/assets/logo.png')
      },
      table: {},
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        { title: "名称", key: "title" },
        { title: "分享描述", key: "context" },
        { title: "失效时间", key: "expireTime" },
        {
          title: "操作",
          render(h, w) {
            const btns = [];
            if (new Date() < new Date(w.row.expireTime)) {
              btns.push(
                h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    on: {
                      click() {
                        _this.$router.push(`/activityShare/${w.row.base}`);
                      },
                    },
                  },
                  "查看"
                )
              );
              btns.push(
                h(
                  "Button",
                  {
                    props: { type: "warning", size: "small" },
                    domProps: { id: "copyBtn" },
                    on: {
                      click() {
                        _this.copyToClipboard(
                          `http://yx.xingyaedu.cn/activityShare/${w.row.base}`
                        );
                      },
                    },
                  },
                  "复制分享链接"
                )
              );
              btns.push(
                h("Button", {
                  props: { type: "info", size: "small" },
                  on: {
                    click() {
                      Object.assign(_this.qrModal, { show: true, src: `${process.env.VUE_APP_BASE_RESEARCH}/activityShare/${w.row.base}`})
                    },
                  },
                }, "查看二维码")
              )
            }
            btns.push(
              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  on: {
                    click() {
                      Object.assign(_this.modal, {
                        show: true,
                        data: util.deepcopy(w.row),
                      });
                    },
                  },
                },
                "更新"
              )
            );
            btns.push(
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  on: {
                    click() {
                      _this.$Modal.confirm({
                        title: "删除",
                        content: `是否删除当前${w.row.title}?`,
                        onOk: () => {
                          deleteShareActivity({ id: w.row.id }).then((res) => {
                            if (res.code == "HA0200") {
                              _this.$Message.success("删除成功！");
                              _this.getData();
                            }
                          });
                        },
                      });
                    },
                  },
                },
                "删除"
              )
            );
            return btns;
          },
        },
      ],
    };
  },
  methods: {
    // 下载二维码
    downLoadQrCode() {
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = '研学分享'
      a.href = this.$refs.qrCode.$el.currentSrc
      a.dispatchEvent(event)
    },
    // 复制到剪切板
    copyToClipboard(shareUrl) {
      if (shareUrl) {
        const clipboard = new ClipboardJS("#copyBtn", {
          text: () => shareUrl,
        });
        clipboard.on("success", (e) => {
          this.$Message.success("复制成功");
          clipboard.destroy();
        });
        clipboard.on("error", (e) => {
          this.$Message.warning("复制失败");
          clipboard.destroy();
        });
      } else {
        this.$Message.warning("请重新复制活动分享链接");
      }
    },
    // 更新分享
    saveData() {
      updateShareActivity(this.modal.data).then((res) => {
        if (res.code == "HA0200") {
          this.$Message.success("更新成功！");
          this.getData();
        }
      });
    },
    // 获取数据列表
    getData(page) {
      Object.assign(this.param, { current: page ? page : 1 });
      if (this.param.query) {
        this.param.order = "title";
        this.param.rule = "";
      } else {
        this.param.order = "createTime";
        this.param.rule = "DESC";
      }
      getShareActivityList(this.param).then((res) => {
        res.code == "HA0200"
          ? (this.table = res)
          : this.$Message.error(res.msg);
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
